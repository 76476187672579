import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Urban Fade
			</title>
			<meta name={"description"} content={"Where Tradition Meets Style"} />
			<meta property={"og:title"} content={"Urban Fade"} />
			<meta property={"og:description"} content={"Where Tradition Meets Style"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66a23551e778710018d2000a/images/652c5e2ab0919-mid-tile.webp?v=2024-07-25T11:30:21.178Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
		</Components.Header>
		<Components.Hero background="linear-gradient(0deg,rgba(4, 16, 41, 0.74) 0%,rgba(4, 16, 41, 0.79) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66a23551e778710018d2000a/images/62bb1405525b9e109bf82d42_2.jpg?v=2024-07-25T11:30:21.197Z) center/cover repeat scroll padding-box">
			<Override slot="link" display="none" />
			<Override slot="text">
				Experience Unmatched Grooming Services
			</Override>
			<Override slot="text1" font="--lead">
				At Urban Fade, we offer a comprehensive range of grooming services designed to make you look sharp and feel confident. Our skilled barbers are dedicated to delivering the highest quality of service, whether you’re in for a quick trim or a complete style overhaul. Step into our world of refined grooming and discover the art of barbering perfected.{"\n\n"}
			</Override>
		</Components.Hero>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66a23551e778710018d2000a/images/51510670_2314051048852105_6875913032054380962_n.jpg?v=2024-07-25T11:30:21.179Z"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/51510670_2314051048852105_6875913032054380962_n.jpg?v=2024-07-25T11%3A30%3A21.179Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/51510670_2314051048852105_6875913032054380962_n.jpg?v=2024-07-25T11%3A30%3A21.179Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/51510670_2314051048852105_6875913032054380962_n.jpg?v=2024-07-25T11%3A30%3A21.179Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/51510670_2314051048852105_6875913032054380962_n.jpg?v=2024-07-25T11%3A30%3A21.179Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/51510670_2314051048852105_6875913032054380962_n.jpg?v=2024-07-25T11%3A30%3A21.179Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/51510670_2314051048852105_6875913032054380962_n.jpg?v=2024-07-25T11%3A30%3A21.179Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/51510670_2314051048852105_6875913032054380962_n.jpg?v=2024-07-25T11%3A30%3A21.179Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="#564aec" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						Crafting Excellence, One Cut at a Time
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="--headline3">
						Our Expert Barbers
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
						Our team of expert barbers is the backbone of Urban Fade. Each member is meticulously trained and passionate about their craft, ensuring that every client receives personalized attention and outstanding results. Our barbers are not only proficient in traditional techniques but also stay updated with the latest trends to offer you a perfect blend of classic and contemporary styles.{"\n\n"}
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66a23551e778710018d2000a/images/about-min.jpeg?v=2024-07-25T11:30:21.194Z"
						display="block"
						width="100%"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						max-height="400px"
						srcSet="https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/about-min.jpeg?v=2024-07-25T11%3A30%3A21.194Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/about-min.jpeg?v=2024-07-25T11%3A30%3A21.194Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/about-min.jpeg?v=2024-07-25T11%3A30%3A21.194Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/about-min.jpeg?v=2024-07-25T11%3A30%3A21.194Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/about-min.jpeg?v=2024-07-25T11%3A30%3A21.194Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/about-min.jpeg?v=2024-07-25T11%3A30%3A21.194Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/about-min.jpeg?v=2024-07-25T11%3A30%3A21.194Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 90px 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
					order="-1"
				>
					<Text margin="0px 0px 10px 0px" color="#564aec" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						Comprehensive Grooming Services{"\n\n"}
					</Text>
					<Text margin="2rem 0px 0 0px" color="--dark" font="--headline3">
						Haircuts
					</Text>
					<Text margin="1rem 0px 0px 0px" font="--base" color="--darkL2">
						Our haircuts are tailored to your unique style and preferences, ensuring you leave with a look that suits you perfectly.
Classic Cut: A timeless haircut that never goes out of style. Perfect for those who appreciate tradition.
Modern Style: Stay on trend with a haircut that incorporates the latest styles and techniques.
Kids' Cuts: Fun and stylish haircuts for the younger members of the family, designed to make them feel special.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66a23551e778710018d2000a/images/65b7db329101b-mid-tile.webp?v=2024-07-25T11:30:21.175Z"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/65b7db329101b-mid-tile.webp?v=2024-07-25T11%3A30%3A21.175Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/65b7db329101b-mid-tile.webp?v=2024-07-25T11%3A30%3A21.175Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/65b7db329101b-mid-tile.webp?v=2024-07-25T11%3A30%3A21.175Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/65b7db329101b-mid-tile.webp?v=2024-07-25T11%3A30%3A21.175Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/65b7db329101b-mid-tile.webp?v=2024-07-25T11%3A30%3A21.175Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/65b7db329101b-mid-tile.webp?v=2024-07-25T11%3A30%3A21.175Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/65b7db329101b-mid-tile.webp?v=2024-07-25T11%3A30%3A21.175Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 30px 0px" color="--dark" font="--headline3">
						Shaves
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
						Experience the ultimate in relaxation with our expert shaving services.
Hot Towel Shave: Indulge in a traditional hot towel shave that leaves your skin smooth and refreshed.
Beard Trim: Keep your beard looking sharp and well-maintained with our precise trimming services.
Straight Razor Shave: Enjoy a close and clean shave with our professional straight razor technique.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66a23551e778710018d2000a/images/Mr.Colt-251.800x600.jpg?v=2024-07-25T11:30:21.169Z"
						display="block"
						width="100%"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						max-height="400px"
						srcSet="https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/Mr.Colt-251.800x600.jpg?v=2024-07-25T11%3A30%3A21.169Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/Mr.Colt-251.800x600.jpg?v=2024-07-25T11%3A30%3A21.169Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/Mr.Colt-251.800x600.jpg?v=2024-07-25T11%3A30%3A21.169Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/Mr.Colt-251.800x600.jpg?v=2024-07-25T11%3A30%3A21.169Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/Mr.Colt-251.800x600.jpg?v=2024-07-25T11%3A30%3A21.169Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/Mr.Colt-251.800x600.jpg?v=2024-07-25T11%3A30%3A21.169Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a23551e778710018d2000a/images/Mr.Colt-251.800x600.jpg?v=2024-07-25T11%3A30%3A21.169Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 90px 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
					order="-1"
				>
					<Text margin="2rem 0px 0 0px" color="--dark" font="--headline3">
						Hair Treatments
					</Text>
					<Text margin="1rem 0px 0px 0px" font="--base" color="--darkL2">
						Enhance the health and appearance of your hair with our specialized treatments.
Scalp Treatment: A revitalizing treatment that nourishes your scalp and promotes healthy hair growth.
Hair Repair: Repair and rejuvenate damaged hair with our intensive repair treatments.
Color Services: From subtle highlights to bold colors, we offer a range of coloring options to suit your style.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://images.unsplash.com/photo-1514336937476-a5b961020a5c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						srcSet="https://images.unsplash.com/photo-1514336937476-a5b961020a5c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1514336937476-a5b961020a5c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1514336937476-a5b961020a5c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1514336937476-a5b961020a5c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1514336937476-a5b961020a5c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1514336937476-a5b961020a5c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1514336937476-a5b961020a5c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 30px 0px" color="--dark" font="--headline3">
						Grooming Packages
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
						Take advantage of our exclusive grooming packages that combine multiple services for a comprehensive grooming experience.
The Gentleman’s Package: Includes a classic haircut, hot towel shave, and scalp treatment for the ultimate grooming experience.
The Executive Package: A modern haircut, beard trim, and hair repair treatment to keep you looking sharp and professional.
The Deluxe Package: A full grooming session with a haircut, straight razor shave, and color services for a complete transformation.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://images.unsplash.com/photo-1537109662156-fade15c5ae8e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						width="100%"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						max-height="400px"
						srcSet="https://images.unsplash.com/photo-1537109662156-fade15c5ae8e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1537109662156-fade15c5ae8e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1537109662156-fade15c5ae8e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1537109662156-fade15c5ae8e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1537109662156-fade15c5ae8e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1537109662156-fade15c5ae8e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1537109662156-fade15c5ae8e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 90px 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
					order="-1"
				>
					<Text margin="2rem 0px 0 0px" color="--dark" font="--headline3">
						Special Services
					</Text>
					<Text margin="1rem 0px 0px 0px" font="--base" color="--darkL2">
						Unique services designed to cater to specific grooming needs and occasions.
Wedding Package: Ensure you look your best on your big day with our tailored wedding grooming package.
Seasonal Styles: Update your look with styles and treatments inspired by the latest seasonal trends.
Custom Consultations: Work with our barbers to create a custom grooming plan that suits your lifestyle and preferences.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/66a23551e778710018d2000a/images/7c4b420cb633bd2bb0ad8efa87e5bf12.jpg?v=2024-07-25T11:30:21.211Z) center center/cover no-repeat"
			min-height="60vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Discover More
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					max-width="60%"
				>
					And this is just the beginning. To explore the full range of our services and to experience the Urban Fade difference, we invite you to visit our barbershop or contact us for more detailed information. Our team is always ready to help you achieve the perfect look.{"\n\n"}
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Contact Us
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});